import styled from 'styled-components'
export default function Card (props){
    return(
        <CARD className="card">
        <div className="image">
            <img src={props.image}/>
        </div>
        <div className="text">
            <p>{props.text}</p>

        </div>
    </CARD>
    )
}
const CARD = styled.div`
display:flex;
flex-direction:column;
background-color: white;
justify-content:center;
align-items:center;
.image{
    width:100%;
    height:100%;
    margin:auto;
    img{object-fit:cover;
    width:100%;
height:100%;
display:block;}
}`