import Card from "../custom/card";
import AOS from "aos";
import "aos/dist/aos.css";
import { Icon } from '@iconify/react';
import { useEffect } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button} from '../custom/button'
import { Wrapper} from '../custom/wrapper'
import { Header} from '../custom/header'
import styled from 'styled-components'
export default function Contact() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
      <Wrapper>
        <CONTACT id="contact">
        <Header className="header">
          <h5><Icon className="header-icon" icon="svg-spinners:bouncing-ball" color="pink" />Contact Us</h5>
          <h3>we are a dial away</h3>
        </Header>
        <div className="body">
          <div className="intro">
          <Icon className="icon" icon="svg-spinners:clock" color="#0082c6" />
          <h5> We are available at every hour of the day to take your calls</h5>
          </div>
        <div className="cards">
         
         <a
           href="tel:+2348034103429"
      
         >
           <div className="tab">
           {/*  */}
             <h5>Give us a call</h5>
             <p>Reach out the the school management any time from hours 7:00 to 17:00 on working days</p>
             <Button blue>Call us <Icon className="icon" icon="teenyicons:phone-solid" color="white" /></Button>
             </div>
         </a>
         <a
           href="mailto:enquiries@eminentzion.com?subject=Hello&body="
    
         >

                      <div className="tab">

             {/* */}
             <h5>Email</h5>
             <p>You can send us an official mail at any time of the day. We are always available to respond.</p>
             <Button blue>Mail Us  <Icon className="icon" icon="fa:envelope" color="white" /></Button>
             </div>
         </a>
         <a
           href="https://wa.me/2348034103429?text=Hello%20Eminent%20School."
    
         >
                       <div className="tab">

            {/*  */}
            <h5>WhatsApp</h5>
             <p>You can chat us up on WhatsApp at any time, we are always available to reply your messages.</p>
             <Button blue >Let's Chat <Icon className="icon" icon="mingcute:whatsapp-fill" color="white" /></Button>
             </div>
         </a>
       </div>
        </div>
   
      </CONTACT>
      </Wrapper>
    
  );
}
const CONTACT = styled.div`
padding:70px 100px;
gap:20px;
background-color: ${(props)=>(props.deep && '#f5f5f5') || 'white'};
.header{
  justify-content: center;
  align-items:center;
}
.body{
  display:flex;
  flex-direction: column;

}
.intro{
  display: flex;
  flex-direction: column;
  align-items:center;
  text-align:center;
  padding-top:20px;
  gap:10px;
.icon{
  font-size:50px;
}
}
.cards{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-top:20px;

.tab{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 5px;
  padding:30px 10px;
  border-radius:20px;
  justify-content: center;
  align-items:center;
  text-align: center;
  /* background:pink; */
  .icon{
    font-size:20px;
  }
  Button{
    width: 60%;
    display: flex;
    gap: 10px;
    align-items:center;
    justify-content:center;
  }
}

}
@media screen and (max-width:700px){
padding:50px 15px;
.body{
.cards{
  display:flex;
  flex-direction: column;
  .tab{
    padding:50px 20px;
  }
}
}
}
`