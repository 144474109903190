export const navLinks = [
    {
        link: "home"
    },
    {
        link: "contact"
    },
    {
        link: "about"
    },
    {
        link: "gallery"
    }
]