import LandingPage from "../Components/landing/LandingPage"
import Bio from "../Components/landing/Bio"
import Photos from "../Components/landing/Photos"
import Contact from "../Components/landing/Contact"
import Testimonials from "../Components/landing/Testimonials"
import Services from "../Components/landing/Services"
import Enroll from "../Components/landing/enroll"
import Welcome from "../Components/landing/Welcome"
export default function Home (){
    return(
        <div>
<LandingPage/>
<Welcome/>
<Services/>
<Photos/>
<Bio/>
<Testimonials/>
<Enroll/>
<Contact/>
        </div>
    )
}