import {styled} from 'styled-components'
export default function Testimony(props) {
    return (
        <TESTIMONY>
<div className="image">
    <img src={props.image}/>
</div>
<div className="text">
    <h5>{props.name}</h5>
    <h6>{props.about}</h6>
    <p>{props.texts}</p>
</div>
        </TESTIMONY>
    );
  }
  const TESTIMONY = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .image {
    width: 150px;
    height: 150px;
    border-radius: 50%;


    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      justify-content: center;
      display: block;
    }
  }
  .text {
    padding: 20px;
    text-align:center;
    p {
      text-align: center;
      width: 65%;
      font-size: 19px;
      margin: auto !important;
     
    }
}
@media screen and (max-width: 800px){
  .text {
    padding:10px;
    p {
      width: 100%;
     
    }
}
}`