import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../src/layouts/Navbar";
import Footer from "../src/layouts/Footer";
import Home from "./Pages/Home";
import AboutUs from "./Pages/About"
import GalleryPage from "./Pages/Gallery"
import "bootstrap/dist/css/bootstrap.css";
function App() {
  React.useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  },[])
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs/>} />
          <Route path="/gallery" element={<GalleryPage/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
