import "bootstrap/dist/css/bootstrap.css";
import Testimony from "../custom/testimony";
import {Header} from '../custom/header'
import {Wrapper} from '../custom/wrapper'
import styled from "styled-components";
import { Icon } from '@iconify/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
export default function Testimonials() {
  return (
   <Wrapper deep>
     <TESTIMONIALS>
      <Header className="header">
        <h5> <Icon className="header-icon" icon="svg-spinners:bouncing-ball" color="pink" />TESTMONIALS</h5>
        <h3>Read what people say about us</h3>
      </Header>
      <div className="wrapper">
        <Carousel
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
             <Carousel.Item className="carousel-item">
              <Testimony
                image="./images/samson.jpeg"
                about="Mass Communication, UNILAG"
                name="Mr Samson Seyifunmi"
                texts="As a proud graduate of Eminnent Zion School, I can attest to the exceptional education I received and the lifelong impact it has had on my success. "
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item ">
              <Testimony
                image="./images/loye.jpeg"
                about="Nursing Science, Chrisland University"
                name="Miss Oluwaloye Oluwadairo (2013 set)"
                texts="As a proud graduate of Eminnent Zion School, I can attest to the exceptional education I received and the lifelong impact it has had on my success. "
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
              <Testimony
                image="./images/sod.jpg"
                about="Accountant"
                name="Mr Soremekun Desmond (2007 set)"
                texts="I am always proud of my alma mater anywhere I go. The school's direction in building up a child is something I had the oppportunity to enjoy during my time in Zion School and I will never take it for granted."
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
              <Testimony
                image="./images/raphael.jpeg"
                about="Fine and Applied Arts, OAU."
                name="Mr Folarin Rapheal (2013 set)"
                texts="I discovered my passion for arts and design while I was a pupil of Zion school. Zion school focuses on building a pupil academically, morally and also help pupils discover thier talents and build their skills."
              />
            </Carousel.Item>
           <Carousel.Item>
           <Testimony
                image="./images/profile.png"
                about="Parent"
                name="Dr Ogundipe"
                texts="I have so much confidence in the institution's capacity to build self confidence and discipline in children. My children are beneficiaries of the excellent teaching and top-notch facilities the school offers."
              />
           </Carousel.Item>
            <Carousel.Item className="carousel-item">
              <Testimony
                image="./images/profile.png"
                about="Parent"
                name="Pastor Ajayi"
                texts="As a parent, I couldn't be more grateful for the educational experience my child has had at Eminent Zion School. The school's commitment to academic excellence, combined with a nurturing environment, has truly set the foundation for their future success."
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
              <Testimony
                image="./images/edward.jpeg"
                about="Agricultural Scientist"
                name="Mr Soremekun Edward (2010 set)"
                texts="This school taught me some everlasting lessons that makes me who I am today and I am very proud and grateful to have been a product of Zion School."
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
              <Testimony
                image="./images/profile.png"
                about="Pupil"
                name="John"
                texts="I love my school so much because the school's environment is calm and clean, the classrooms are spacious, and my teachers are nice"
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
              <Testimony
                image="./images/profile.png"
                about="Staff (class teacher)"
                name="Mrs Aigbe"
                texts="All the teaching and non-teaching staff of this great citadel of learning have always been pushed to make the lives of our pupils become what we can be most proud of."
              />
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
              <Testimony
                image="./images/profile.png"
                about="Pupil"
                name="Oluwatobiloba (basic 5 pupil)"
                texts="I love my teachers so much because they are my second parents. They treat us love, the fear o God and with discipline. I know I will grow up to become somebody great because of this school and my teachers."
              />
            </Carousel.Item>
        </Carousel>
      </div>
    </TESTIMONIALS>
   </Wrapper>
  );
}
const TESTIMONIALS = styled.div`
 padding: 70px 100px;
  width: 100%;
.header{
  justify-content: center !important;
align-items: center !important;
text-align:center;
}
.wrapper{
  padding-top: 50px;
}

  .carousel {
    width: 100%;
    text-align: center;
    a {
      background: transparent;
      padding: 0 !important;
    }
    &-inner {
      padding-bottom: 40px;
    }

    &-indicators {
      gap: 10px;
      margin-top: 20px !important;
      .active {
        background-color: #0082e6 !important;
      }
      li {
        background-color: pink !important;
        list-style: none;
        padding: 2px 10px;
      }
    }
  }
  @media screen and (max-width: 700px){
    padding: 25px 15px;
  }
`