import React from "react";
import styled from "styled-components";
// import Card from "../custom/card";
// import Typewriter from "typewriter-effect";
import { useEffect } from "react";
import {Button} from "../custom/button";
import {Wrapper} from "../custom/wrapper"
import AOS from "aos";
import "aos/dist/aos.css";
import { Icon } from '@iconify/react';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import {divVariants} from "../custom/varients";
export default function LandingPage() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
   <Wrapper>
     <Banner>
      <motion.div className="left"
      variants={divVariants.bodyAnimation}
      initial = "hidden"
      animate= "visible" 
      >
        <h2 className="schoolName">EMINENT <span>ZION</span> SCHOOL</h2>
        <p>
         Groomig future leaders to become the best, <br/>
         Building a better society with quality education,<br/>
         Enabling children with appropriate skills and knowledge.<span>.</span>
        </p>
        <div className="buttons">
          <Button blue><Link to = "/about" className="react-router-link"
           onClick={() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          }}>About Us</Link></Button>
          <Button transparent border>
            <a href="#contact"> Contact Us</a>
          </Button>
        </div>
      </motion.div>
      <div className="right">
        <div className="longImage"><img src="./images/photo (1).jpg" /></div>
        <div className="shortImages">
        
        <div className="img-1">
            <img src="./images/photo (1).jpg" />
          </div>
          <div className="img-wrapper img-2">
            <img src="./images/photo (1).jpg" />
          </div>
          <div className="img-wrapper img-3"><img src="./images/photo (1).jpg" /></div>
          <div className="img-wrapper img-4"><img src="./images/photo (1).jpg" /></div>
        </div>
      </div>
    </Banner>
   </Wrapper>
  );
}
const Banner = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 150px 100px;
gap: 50px;
overflow:hidden;
.left{
  width: 50%;
  gap: 20px;
  display:flex;
  flex-direction:column;
  p{
    color:black !important;
  }
 span{
  color: #0082e6;
 }
  .schoolName{
    font-size: 50px;
  }
  p{
  }
  .buttons{
    display: flex;
    gap: 10px;
    Button{
      display:flex;
      gap: 10px;
      align-items:center;
      .icon{
        display: none;
      }
      &:hover{
        .icon{
    display:flex !important;
    transition: 1.2s;
    color: rgb(59, 58, 58) !important;
        }
      }
      
      }
     
    }
  }

.right{
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 50%;
  justify-content:flex-end;
 
}
.longImage{
  height: 400px;
    width: 250px;
    img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
}
  .shortImages {
    height: auto;
    width: 200px;
    gap: 10px;
  display:flex;
  flex-direction: column;
    .img-wrapper {
      height: 127px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-self:center ;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
    .img-1{
      display:none;
      img{
        display:none;
      }
    }
  }
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:50px 10px;
    gap: 50px;
    padding-top: 150px !important;
  
    .left{
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
  .schoolName{
    font-size: 40px;
  }
  .buttons{
    justify-content: center;
  }



}
.right{
  width: auto;
  justify-content: center !important;
  align-items: center;
  overflow: hidden !important;
}
.longImage{
display: none;


}
.shortImages {
   margin-right:-20px;
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content:center !important;
    align-items:center;
    overflow:hidden !important;
    .img-wrapper {
     width:220px;
   border-radius:150px;
img {
   border-radius:150px;
        width: 90%;
        height: 90%;
        object-fit: cover;
        display: block;
        margin:auto;
      }
    }
 /* .img-1{
  height: 250px;
  width:220px;
  border-radius:150px;
   display:flex !important;
    img{
       border-radius:150px;
        width: 90%;
        height: 90%;
        object-fit: cover;
        display: block;
        margin:auto;
    overflow: hidden;


    }
  } */
  .img-2{
    height: 300px !important;
   width: 200px !important;
   background-color:pink;
    img{

    }
  }
  .img-3{
    height:350px;
   background-color:#0082e6;

    img{
   border-radius:150px;
    }
  } 
   .img-4{
    height: 300px;
    
   background-color:pink;

    img{
      
    }
  }
 
  }
  }
  `;
