import styled from 'styled-components'
export const Button = styled.button`
background-color: ${(props => (props.blue && '#0082e6') || (props.transparent && 'transparent'))};
border: ${(props=>(props.border && '1px solid #0082e6')|| '1px solid #0082e6')};
color: ${(props=>(props.blue && 'white')|| (props.transparent && '1px solid rgb(59, 58, 58)'))};;
padding: 10px 24px;
border-radius: 10px;
font-size:15px !important;
font-weight:700;
a{
  text-decoration:none !important;

}

@media screen and (max-width:700px) {
  font-size: 14px !important;
}
&:hover{
    border: ${(props=>(props.border && '1px solid #0082e6' )|| '1px solid #0082e6')};
color: ${(props=>(props.blue && '#0082e6')|| (props.transparent && 'white'))};;
background-color: ${(props => (props.blue && 'transparent') || (props.transparent && '#0082e6'))};  
transition:0.5s;
.icon{
    display:flex !important;
    transition: 1.2s;
    color: #0082e6 !important;
}
a{
  text-decoration:none !important;
  color:inherit !important;
  border:0 !important;
}
}
`
