import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import styled from 'styled-components'; 
import {Button} from "../custom/button"
import {Header} from "../custom/header"
import {motion} from 'framer-motion'
import {divVariants} from '../custom/varients'
export default function Enroll(){
    return(
        <ENROLL> 
     <motion.div className="content"
     variant={divVariants.photoAnimation}
     initial= "hidden"
     animate = "visible">
      <h5>JOIN US</h5>
      <h3>Ready to join us?</h3>
     <p style={{color: "white"}}>
          Enrol your child(ren) with us today and be guaranteed of optimum
          sucess
        </p>{" "}
     </motion.div>
    <div className="buttons">
    <a href="https://wa.me/2348034103429?text=Hello%20Eminent%20School.%20I%20want%20enroll%20">
          <Button transparent >Enroll Now !</Button>
        </a>
    </div>
        </ENROLL>
    )
}
const ENROLL = styled.div`
display: flex;
flex-direction: row;
padding:30px 100px;
align-items:center;
background: #0082e6;
    .content {
      padding-top:20px;
      flex-direction: column;
      align-items: left;
      justify-content: left;
      width: 100%;
      text-align:left;
      background: transparent;
      padding-bottom:20px;
      p{
         color:white;
      }
      @media(max-width: 700px){
      h5{
        font-size:22px;
      }
      h3{
        font-size: 30px;

      }
    }
      h5{
         font-size:16px !important;
         color:white;
      }
      h3{
         font-weight:800 !important;
    font-size: 45px;
    color: white;
      }
   }
   .buttons{
   display:flex;
   flex-direction:row;
  Button{
   width:150px;
   border: 1px solid white !important;
   color:white !important;
  }
   }
    @media screen and (max-width: 700px){
padding:30px 15px;
flex-direction:column;
.content{
   align-items: center;
      justify-content: center;
      text-align:center;
}
    }
`