import { Header } from "../custom/header";
import { useEffect } from "react";
import styled from "styled-components";
import { Wrapper } from "../custom/wrapper";
import AOS from "aos";
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {motion} from 'framer-motion'
import {divVariants} from "../custom/varients";
export default function Bio() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Wrapper>
      <Why>
  <div className="wrapper">
  <div className="left">
          <div className="header">
            <h5>
              the school, <span>Eminent Zion School</span>
            </h5>
            <h3>why you should choose us</h3>
          </div>
          <div className="image">
          <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active img">
            <img className="img" src={"images/photo (3).jpg"} />
            </div>
            <div className="carousel-item img">
            <img className="img" src={"images/photo (3).jpg"} />
            </div>
          </div>
        </div>
          </div>
        </div>
        <motion.div className="right"
        variant={divVariants.textAnimation}
        initial = "hidden"
        animate ="visible">
          <div className="contents">
            <div className="content">
              <h5><span>01.</span> Government certified instituite of learning</h5>
              <p>
                Eminent Zion School, Akole Oke-Ata is a fully screened and
                certified school by ocal and state and government
              </p>
            </div>
            <div className="content">
              <h5><span>02.</span>  Experienced teaching and non-teaching staff</h5>
              <p>
                Eminent Zion School, Akole Oke-Ata is a fully screened and
                certified school by ocal and state and government
              </p>
            </div>
            <div className="content">
              <h5><span>03.</span> Top-Notch facilites</h5>
              <p>
                Eminent Zion School, Akole Oke-Ata is a fully screened and
                certified school by local and state and government
              </p>
            </div>
            <div className="content">
              <h5><span>04.</span> 30 years experience</h5>
              <p>
                Eminent Zion School, Akole Oke-Ata is a fully screened and
                certified school by ocal and state and government
              </p>
            </div>
          </div>
        </motion.div>
  </div>
      </Why>
    </Wrapper>
  );
}
const Why = styled.div`
  padding:70px 100px;
  display: flex;
  height:auto;
  align-items:center !important;
  .wrapper{
    flex-direction: row;
  justify-content: space-between;
  align-items:center !important;
  gap: 50px;
    display: flex;
      flex-direction: row;
      width:100%;
    }
  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    .header{
      justify-content: left;
      text-align: left;
      align-items: left;
      h5 {
  display: flex;
justify-content:left ;
align-items:left;
text-transform:uppercase;
font-size:16px !important;
  color: #0082e6;
}
h3{
    color: black;
    font-weight:800 !important;
    font-size: 45px;
    display:flex;
    justify-content:left;
    text-align:left;
}
    }
    .image, .img{
      width: 100%;
      height: 100%;
      overflow: hidden;
  height: 400px;

      
      img{
        display:block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .image{

    }
  }
  .right {
    width: 50%;
    .contents {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: left;
      justify-content:center !important;
      
      .content {
        span{
          font-weight: 700 !important;
          font-size: 30px;
    color: #0082e6 !important;
    @media (max-width: 700px){
      color:pink !important;
    }
           
        }
        h5{
          font-weight: 500 !important;
          font-size:22px;
        }
      }
    }
  }
  @media (max-width: 700px) {
    padding:50px 15px;
    .wrapper{
      flex-direction: column;
      padding:30px 20px;
      padding-top:0px !important;
      background-color: #0082e6;
      border-radius: 20px;
      justify-content: left !important;
      align-items: left !important;
    gap: 0px;
    .contents{
      padding-top:20px;
      h5{
        color:white !important;
      }
    }
    }
    
   
    flex-direction: column;

    height: auto;
    .header {
      align-items: center;
      text-align: center;
      justify-content:content;
      h3{
        color: white !important;
        font-size:30px !important;
        justify-content:center !important;
    text-align:center !important;
    
  

      }
    }
    .left{
      .image{
        display:none;
      }
    }
    .left,
    .right {
      width: 100%;
      p{
        color: white;
      }
    }
  }
`;
