import styled from 'styled-components'
export default function Logo (){
    return(
<LOGO>
    <div className="image">

<img src="/images/logo.png"/>
    </div>
    <div className="text">
<p>Eminent</p>
<p>Zion School</p>
    </div>
</LOGO>
    )
}
const LOGO = styled.div`
display:flex;
flex-direction:row;
align-items:center;
.image{
    height:60px;
width: 60px;
img{
    display:block;
    height:100%;
    width:100%;
    object-fit:cover;
}
}
.text{
    display:flex;
    flex-direction:column;
    line-height:0.3 !important;
    margin-top:13px;
    p{
        color: black !important;
        font-weight: 600;
    }
}
`