export const divVariants = {
bodyAnimation :{
hidden :{
x: -500
},
visible: {
    x: 0,
    transition:{
        duration: 0.8
    }
}
  },
  textAnimation:{
    hidden:{
        x: 900,
        opacity : 0
    },
    visible:{
        x: 0,
        opacity: 1,
        transition: {
            duratioin : 0.8
        }
    }
  },
  photoAnimation: {
    hidden:{
        y: 600
    },
    visible :{
        y:0,
        transition:{
            duration: 0.8
        }
    }
  }
}