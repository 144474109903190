import styled from "styled-components"
import { Button } from "../Components/custom/button"
import { Header } from "../Components/custom/header"
import React from "react";
import { Icon } from '@iconify/react';
import Enroll from '../Components/landing/enroll'
import Contact from '../Components/landing/Contact'
export default function AboutUs (){
    return(
      <About>
        <div className="header-intro">
          <h5>GROOMING FUTURE LEADERS</h5>
          <h3>Building future leaders in best possible way</h3>
        </div>
        <div className="header-image">
          <img src="./images/photo (2).jpg"/>
        </div>
   <div className="intro">
   <div className="header">
    <h5>ABOUT US</h5>
   </div>
<p>
  Established in 1997 by Bishop Dr. Olaoluwa Oluwadairo, the school was formerly known as Zion International Nursery and Primary School (ZINPS) has since then been a great citadel of learning for grooming and nuturing great minds; giving pupils all the resources to be world class and top notch, possesing the abilities to meet societal and religious standards. Eminent Zion School has always been dedicated to building a society of God-fearing people and has been the best at it throughout the years, this, the school has achieved with God's help and our dedication to our vision and mission.
</p>
   </div>
   <Header className="sub-header">
    <h3>Why we are the best at we do</h3>
   </Header>
   <div className="infos">
              <div className="info">
                <h3>20+</h3>
                <p>Years Experience</p>
              </div>
              <div className="info">
                <h3>2000+</h3>
                <p>Students Trained</p>
              </div>
              <div className="info">
                <h3>#1</h3>
                <p>Top 1 Teachers</p>
              </div>
              <div className="info">
                <h3>20+</h3>
                <p>Years Experience</p>
              </div>
            </div>
            <div className="services">
            <div className="about">
              <div className="header">
                <h5>EMINENT ZION</h5>
                <h3>Join us to enjoy education at its peak</h3>
              </div>
              <p>We are the best at grooming and building future leaders that meet societal, cutural and moral standards. Join us today to make sure your wards enjoy optimum servicesand guaranteed successes.</p>
            <Button blue className="button">Enroll Today</Button>
            </div>
            <div className="props">
            <div className="prop"><Icon className="icon" icon="ion:card-outline" color="white" />
            <p>Affordable fees</p>
            </div>
<div className="prop"><Icon className="icon" icon="teenyicons:bulb-on-outline" color="white" />
<p>Enlightening Activities</p>
</div>
<div className="prop">
<Icon className="icon" icon="ph:hands-praying-duotone" color="white" />
<p>Religious Practises</p>
</div>
<div className="prop">
<Icon className="icon" icon="ic:twotone-security" color="white" />
<p>Optimum Security</p>
</div>
<div className="prop">
<Icon className="icon" icon="pepicons-pencil:flower" color="white" />
<p>Conducive Environment</p>
</div>
<div className="prop">
<Icon className="icon" icon="ph:graduation-cap" color="white" />
<p>Standard Programmes</p>
</div>

            </div>

            
            </div>
            <Enroll/>
            <Contact deep/>
      </About>
  );
}
const About = styled.div `
display: flex;
flex-direction:column;
h5{
    font-size:16px;
    font-weight:500;
color:#0082e6;

  }
padding:100px 0px;
.header-intro{
  color:black;
  justify-content: left;
  display:flex;
  flex-direction:column;
  width:50%;
  padding:50px 100px;
  text-align:left;

  h3{
font-size:45px;
font-weight:700;
  }
}
.header-image{
  height:400px;
  img{
    width:100%;
    height:100%;
    object-fit:cover;
    display:block;
    overflow:hidden;

  }
}

.sub-header{
margin-top: 50px;
display:flex;
    justify-content:center;
h3{
  text-align:center;
    width:700px;
  padding:10px 100px;
  width:700px;
  color:black !important;
  font-weight:700 !important;
}
}
.intro{
  .header{
  h5{
    text-transform: uppercase !important;
    justify-content:left ;
align-items:left;
color:#0082e6;
  }
  text-align:left;
  justify-content:left;
}
padding: 50px 100px;
gap: 20px;
display: flex;flex-direction:column;
p{
  width:70%;
}
.image{
  /* border:1px solid red; */
  width:50%;

}
}
.infos{
  padding: 10px 100px;
  margin-top: 10px;
  padding-bottom:70px !important;
  height:auto ;
  background-image:#f5f5f5;
        display: flex;
        gap: 20px;
        /* border: 1px solid black; */
        justify-content: space-between;
        align-items:center;
        .info{
          display:flex;
flex-direction: column;
align-items: center;
          h3{
            font-weight:1000;
            color:#0082ec;
            font-size: 35px;
          }
        }
      }
      .services{
        padding:50px 100px;
        height:auto;
        background-color:black;
        display:flex;
        flex-direction:row;
        gap:50px;
        align-items:center;
        .about{
          display:flex;
          flex-direction:column;
          width:50%;
          gap:20px;
          p{
            color:white;
          }
          .header{
          
          h5{
            display:flex ;
          color:white !important;

         }
         h3{
          color:white;
            font-size:45px;
    font-weight:800 !important;
    text-align:left;

          }
          }
          .button{
            width:30%;
          }
        }
        .props{
width:50%;
display:grid;
grid-template-columns: repeat(2, 1fr);
height: 100% !important;
justify-content:space-between !important;
gap:20px;
.prop{
  display:flex;
  flex-direction:column;
  justify-content:left;
  gap:5px;
  p{
    color:white;
  }
}
.icon{
  /* padding:40px; */
  font-size:50px;
  background-color: #0082e6;
  padding:10px;
  border-radius:10px;
}
        }
      }
      @media(max-width: 700px){
        .header-intro{
  width:100%;
  padding:50px 15px;

  h3{
font-size:30px;
  }
}
.header-image{
  height:170px;
}
.intro{
padding: 50px 15px;
p{
  width:100%;
}
}
.sub-header{
margin-top: 20px;
display:flex;
    justify-content:center;
h3{
  font-size:30px;
  text-align:center;
  padding:10px 15px;
  width:100%;
}
}
.infos{
  padding: 10px 15px;
  display:flex;
  flex-direction:column;
  justify-content: left !important;
  align-items: left !important; 
        .info{
          justify-content: left !important;
          text-align: left !important;  
          h3{
            font-size: 35px;
            text-align: center;
          }
        }
      }
      .services{
        padding:50px 15px;
height:1000px;
        flex-direction:column;
        gap:50px;
        align-items:center;
        .about{
          width:100%;
          gap:20px;
          .header{
         h3{
            font-size:30px;
    font-weight:700 !important;

          }
          }
          .button{
            width:50%;
            position:absolute;
            margin-top:890px;
          }
        }
        .props{
width:100%;
justify-content: start !important;
        }
      }
    }
      `