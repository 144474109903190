import { Link } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "@iconify/react";
export default function Footer() {
  return (
    <End>
      <div className="wrapper">
        <div className="first">
          <h5>Eminent Zion</h5>
          <p>&copy; 2023. EMINENT ZION. All rights reserved</p>
          <div className="social">
            <Icon
              className="icon"
              icon="ant-design:facebook-filled"
              color="#0082e6"
            />
            <a href="https://wa.me/2348034103429?text=Hello%20Eminent%20School.">
              <Icon className="icon" icon="uim:whatsapp" color="#0082e6" />
            </a>
            <Icon className="icon" icon="uil:instagram" color="#0082e6" />
          </div>
        </div>
        <div className="second">
          <h5>WHY EMINENT ZION</h5>
          <ul className="nav-links">
            <li>
              <Link to="/about" className="react-router-link">
                About Us
              </Link>
            </li>
            <li>
              <a href="#services">Our Services</a>
            </li>
            <li>
              <a href="#">Make Enquiries</a>
            </li>
          </ul>
        </div>
        <div className="third">
          <h5>GET IN TOUCH</h5>
          <p>Eminent Zion School, Akole Oke-Ata, Abeokuta, Ogun State.</p>
          <a href="tel:+2348034103429">+2348034103429</a>
          <a href="mailto:enquiries@eminentzion.com?subject=Hello&body=">
            enquiries@eminentzion.com
          </a>
        </div>
      </div>
      <div className="end">
        designed by &nbsp;
        <a href="https://wa.me/2348124853914?text=Hello%20Ferincodes.">
          Ferincodes
        </a>
      </div>
    </End>
  );
}
const End = styled.div`
  background: black;
  display: flex;
  flex-direction: column;
  .wrapper {
    display: flex;
    flex-direction: row;
    padding: 70px 100px;
    justify-content: space-between;
    gap: 30px;
    .first,
    .second,
    .third {
      display: flex;
      flex-direction: column;
      width: 33%;
      text-align: left;
      justify-content: right;
      ul {
        margin-left: 0;
        padding-left: 0;
      }
      h5,
      p,
      li,
      a {
        color: white;
        list-style: none;
      }
      .social {
        gap: 10px;
        display: flex;
        flex-direction: row;
        .icon {
          font-size: 50px;
          padding: 10px;
          background-color: white;
          border-radius: 50%;
        }
      }
    }
    .first {
      gap: 10px;
    }
  }
  .end {
    display: flex;
    justify-content: center;
    color: white;
    padding: 30px;
    border-top: 1px solid grey;
    a {
      color: white;
      list-style: none;
    }
  }
  @media screen and (max-width: 700px) {
    .wrapper {
      padding: 50px 15px;
      flex-direction: column;
      .first,
      .second,
      .third {
        width: 100%;
        .icon {
          font-size: 40px;
          padding: 7px;
          background-color: white;
          border-radius: 50%;
        }
      }
    }
  }
`;
