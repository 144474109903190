import Card from "../custom/card";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Wrapper } from "../custom/wrapper";
import { Button } from "../custom/button";
import styled from "styled-components";
import { Header } from "../custom/header";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
export default function Photos() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Wrapper deep>
      <Gallery className="photos" id="gallery">
        <Header className="header">
          <h5>
            <Icon
              className="header-icon"
              icon="svg-spinners:bouncing-ball"
              color="pink"
            />
            Gallery
          </h5>{" "}
          <h3>Take a peak at some of our photos</h3>
        </Header>
        <div className="body">
          <Carousel className="first-wrapper">
            <Carousel.Item>
              <img
                className=""
                src="./images/photo (1).jpg"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className=""
                src="./images/photo (1).jpg"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className=""
                src="./images/photo (1).jpg"
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
          <div className="second-wrapper">
            <Card image="./images/photo (2).jpg" data-aos="fade-left" />
            {/* <Card
          image="./images/photo (3).jpg"
          data-aos="fade-right"
          /> */}
          </div>
          <div className="third-wrapper">
            <Card image="./images/photo (2).jpg" data-aos="fade-left" />
            <Card image="./images/photo (3).jpg" data-aos="fade-right" />
          </div>
        </div>
        <div className="button">
          <Button blue>
            {" "}
            <Link to="/gallery" className="react-router-link"
             onClick={() => {
              window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            }}>
              see more photos
            </Link>
          </Button>
        </div>
      </Gallery>
    </Wrapper>
  );
}
const Gallery = styled.div`
  padding: 100px;
  height: auto;
  .body {
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 500px;
    gap: 20px;
    .first-wrapper {
      height: 100%;
      width: 600px;
      overflow: hidden !important;
      grid-row-end: span 2;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 10px;
      }
    }
    .second-wrapper {
      grid-column-end: span 2;
    }
    .third-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      grid-column-end: span 2;
    }
    .card {
      border-radius: 10px;
      overflow: hidden;
      height: 250px;
      .image {
        width: 100%;
        height: 100%;
        margin: auto;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 10px;
        }
      }

      .text {
        position: absolute;
        height: 100%;
        width: 100%;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
  }
  @media screen and (max-width: 700px) {
    padding: 25px 0px;
    .body {
      /* padding-top: 10px; */
      display: flex;
      flex-direction: row;
      overflow: scroll;
      width: auto;
      height: 400px;
      .first-wrapper {
        display: none !important;
      }

      .card {
        height: 300px;
        width: 300px !important;
      }
      .second-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    .button {
      margin-top: 10px;
    }
  }
`;
