// import Card from "../custom/card";
import { useEffect } from "react";
import AOS from "aos";
import {Header} from '../custom/header'
import "aos/dist/aos.css";
import { Icon } from '@iconify/react';
import { Wrapper } from "../custom/wrapper";
import styled from "styled-components";
import {Button} from "../custom/button"
export default function Services() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Wrapper id="services">
      <Offered >
     <Header className="header">
          <h5><Icon className="header-icon" icon="svg-spinners:bouncing-ball" color="pink" />What we offer</h5>
          <h3>Our services</h3>
        </Header>
     <div className="wrapper">
        <div className="body">
        <div className="tab">
        <Icon className="icon" icon="icon-park-solid:bottle" color="white" hFlip={true} />
        <h4>
          Creche
        </h4>
        <p>
          conducive ennvironment for babies and toddlers.
        </p>
        {/* <Button blue> know more</Button> */}
        
          </div> 
          <div className="tab">
        
        <Icon className="icon" icon="tabler:puzzle-filled" color="white" />
        <h4>
          Nursery
        </h4>
        <p>
      we offer the best services for young children.
        </p>
        {/* <Button blue> know more</Button> */}
        
          </div> 
          <div className="tab">
      
          <Icon className="icon" icon="fa6-solid:graduation-cap" color="white" hFlip={true} />
        <h4>
          Primary
        </h4>
        <p>
         standard and appropriate education for pupils.
        </p>
        {/* <Button blue> know more</Button> */}
        
          </div> 
          <div className="tab">
          <Icon className="icon" icon="mdi:rocket" color="white" hFlip={true} />
          
          <h4>
          Pre-secondary
        </h4>
        <p>
          coaching sessions to prepare your wards for secondary education.
        </p>
        </div> 
        </div>
     </div>
     <div className="button">
      <a href="https://wa.me/2348034103429?text=Hello%20Eminent%20School.%20I%20want%20enroll%20">
        <Button blue> know more</Button>
      </a>

     </div>
      </Offered>
    </Wrapper>
  );
}
const Offered = styled.div`
  padding: 70px 100px;
  .header{
    text-align:center !important;
    align-items:center !important;
    justify-content:center !important;
  }
.wrapper{
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: transparent;
  padding:40px 20px;
  gap: 20px;
  .body{
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:20px;
.tab{
display: flex;
flex-direction: column;
border-radius:10px;
padding:30px 20px;
align-items: center;text-align:center;
box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
/* box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px; */
/* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
//box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
.icon{
    color:white !important;
  background:#0082e6;
  padding: 7px;
  font-size: 35px;
  border-radius: 12px;
  }
  h4{
    color:#0082e6;
    padding-top: 10px;
  }
h5{
    padding-top:10px;

  }
}

}
}
.button{
  padding-top: 50px;
  width: 100%;
  display:flex;
  justify-content:center;
  display:flex;
  
}
@media screen and (max-width: 700px){
  padding:50px 15px;
  .wrapper{
    padding: 40px 10px;
      .body{
      grid-template-columns: repeat(1, 1fr);
      padding-top: 10px;
      .tab{
        border-right: 0;
        padding:50px 20px;
        Button{
          width:50%;
        }
      }
    }
    }
    .button{
  padding-top: 20px !important;
  
}
  }
`;
