import { styled } from "styled-components";
import { useState } from "react";
import { navLinks } from "../Components/Routes/navlinks";
import { Button } from "../Components/custom/button";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import Logo from "../Components/logo";
import React from 'react'
export default function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  return (
    <NavWrapper className="navbar">
      <motion.nav className={`navbar ${isNavOpen ? "nav-opened" : "nav-closed" }`}
      initial={{y: -300}}
      animate= {{y: 0}}
      transition={{stiffness: 300, duration: 0.7}}>
      <button
          className="navbar-btn"
          onClick={() => {
            setIsNavOpen(!isNavOpen);
            console.log(navLinks)
          }}
        >
          <Icon className="icon" icon={isNavOpen ? "iconoir:cancel" : "fe:bar"} color= "black" />
        </button> <label className="logo"><Link to = "/" className="react-router-link"
         onClick={() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          }}>
          <Logo/>
          </Link></label>
        <ul className="nav-links">
          <li>
          <Link to = "/about" className="react-router-link"
           onClick={() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          }}>About</Link>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          {/* <li>
            <a href="#contact">Contact</a>
          </li> */}
          <li>
          <Link to = "/gallery" className="react-router-link"
           onClick={() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          }}>Gallery</Link>

          </li>
        </ul>
        <Button transparent border className="contact"> 
        <a href="https://wa.me/2348034103429?text=Hello%20Eminent%20School.%20I%20want%20enroll%20">
      Enroll
        </a></Button>
      
        <ul className={`mobile-nav ${isNavOpen ? "opened" : "closed"}`}>
        
          <li>
          <Link to = "/about" className="react-router-link"
           onClick={() => {
            setIsNavOpen(!isNavOpen);
            console.log(navLinks)
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          }}>About</Link>
          </li>
          <li>
            <a href="#services"
             onClick={() => {
              setIsNavOpen(!isNavOpen);
              console.log(navLinks)
              
            }}>Services</a>
          </li>
          <li>
            <a href="#contact" onClick={() => {
            setIsNavOpen(!isNavOpen);
            console.log(navLinks)
          }}>Contact Us</a>
          </li>
          <li>
          <Link to = "/gallery" className="react-router-link"
           onClick={() => {
            setIsNavOpen(!isNavOpen);
            console.log(navLinks)
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          }}>Gallery</Link>
          </li>
        </ul>
      </motion.nav>
    </NavWrapper>
  );
}
const NavWrapper = styled.div`
padding: 0 !important;
  nav {
box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    padding:0 100px;
    background: white;
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
margin-top: 70px !important;
z-index: 999 !important;
  }
  .navbar-btn{
    display:none; 
  }
  .logo{
color: black;
font-size: 30px;
  }
  .mobile-nav {
    display: none;
    
  }
.nav-links{
  display: flex;
  align-items:center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  gap: 10px;
 }
 li {
    list-style: none;
font-weight:500 !important;

   a {
    padding: 5px 10px ;
    color: black;
    font-size: 17px;
    border-bottom: 1px solid white;
    /* text-transform: uppercase; */
font-weight:500 !important;
 
  }}
  a:hover {
    transition: 0.5s;
    color: grey;

  }
  @media (max-width: 952px) {
    label.logo {
      font-size: 30px;
    
    }
    nav ul li a {
      font-size: 16px;
    }
  }
  @media (max-width: 858px) {
    nav{ 
margin-top: 60px !important;
      padding: 10px;
      height: 70px;
      .navbar-btn{
    display: flex;
.icon{
  color: black !important;
  display:block;
  font-size:30px !important;
}

  }
    } 
    .nav-opened{
      justify-content:left !important;
      align-items: left;
      gap: 20px;

      .logo{
color: black;
  }

  .contact{
    display: none !important;
  }
    }
    .navbar-btn{
    display:block; 
    background: transparent;
    border: 0;
  }
  .nav-links{
  display: none;
}
.mobile-nav{
  display: block;
  background-color: white;
  transition: 0.5s;

  a{
    color: black !important;
    border-radius: 0;
    border: 0 !important;
  }
}
    .opened {
      position: fixed;
      width: 100%;
width: 100%;
      height: 100vh;
      top: 80px;
      left: 0%;
      text-align: center;
      transition: 0.5s;
      /* gap: 20px; */
      padding: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
 margin-top:-20px !important;

      li {
    list-style: none;
    margin-top: 20px;
   a {
    padding: 5px 10px ;
    color: white;
    font-size: 17px;
    border-radius: 3px;
    text-transform: uppercase;
  }}

    }
    .closed {
      margin-left: -300% !important;
      transition: 0.5s;
     position: absolute;

    }
    }
`;
