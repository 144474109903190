import { styled } from "styled-components";
export const Header = styled.div `
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
h5 {
  display: flex;
justify-content:center ;
align-items:center;
text-transform:lowercase;
font-weight:400 !important;
font-size:17px !important;
  gap: 5px;
  color: ${(props) => (props.white && 'white') || (props.black && 'black')|| '#0082e6'};
  .header-icon{
    font-weight:700 !important;
  }
}
h3{
    color: ${(props) => (props.white && 'white') || 'black'};
    font-weight:800 !important;
    font-size: 45px;
    display:flex;
    justify-content:center;
    text-align:center;
    width:700px;
   

}
@media(max-width: 700px){
      h5{
        font-size:22px;
      }
      h3{
        font-size: 30px;
        width:100% !important;

      }
    }
`