import styled from "styled-components"
import {Header} from "../Components/custom/header"
import {motion} from 'framer-motion'
import {divVariants} from '../Components/custom/varients'
import {Icon} from '@iconify/react';
export default function GalleryPage (){
return(
<Gallery>
<Header>
    <h5><Icon className="header-icon" icon="svg-spinners:bouncing-ball" color="pink" />Gallery</h5>
    <h3>Our photos tell you about us</h3>
</Header>
<div className="body">
<motion.div className="top"
variant={divVariants.bodyAnimation}
initial="hidden"
animate="visible">
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/founder.jpeg"/>
<div className="text">
    <p>Bishop Dr. Olaloluwa Oluwadairo</p>
    <h5>Founder and Proprietor</h5>
</div>
</motion.div>

<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/proprietress.jpeg"/>
<div className="text">
    <p>Mrs Oluwadairo Ruth</p>
    <h5>Coordinator</h5>
</div>
</motion.div>
</motion.div>
<div className="wrapper">
<Header>
    <h5 className="header" style={{color: 'black', fontWeight: "600"}}>Photos of our pupils</h5>
</Header>
<div className="general">
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/photo (1).jpg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/photo (2).jpg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/photo (3).jpg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/grad.jpg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/grad-2.jpg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/grad-3.jpg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/grad.jpg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/photo (1).jpg"/>
</motion.div>
</div>
</div>
<div className="wrapper">
<Header>
    <h5 style={{color: 'black', fontWeight: "600"}} className="header">Some of our alumni</h5>
</Header>
<div className="history">
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/sod.jpg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/raphael.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/loye.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/samson.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/mariam.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/larry.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/pupil3.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/pupil2.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/dswagg.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/ferrum.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/pupil5.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/pupil.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/twins.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/yetunde.jpeg"/>
</motion.div>
<motion.div className="image"
variant={divVariants.photoAnimation}
initial = "hidden"
animate = "visible">
    <img src="./images/pupil4.jpg"/>
</motion.div>

</div>
</div>
</div>
</Gallery>
)
}
const Gallery = styled.div`
padding:120px 90px;
.body{

    display:flex;
    flex-direction: column;
    gap:70px;
    .history, .general{
        padding-top:20px;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content:space-between;
        align-items:center;
        gap:20px;
        .header{
font-weight:600 !important;
font-size: 17px !important;
        }
        .details-wrapper{
            display:flex;
            flex-direction:column;
            align-items:center;
            gap:10px;
            .details{
                display:flex;
                flex-direction:column;
            
                align-items:center;
                text-align:center;
            }

        }
        .image{
height:250px;
width: 250px;
/* overflow:hidden; */
img{
    height:100%;
    width:100%;
    object-fit: cover !important;
    display:block;
}
        }
    }
    .top{
        padding-top: 50px;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content:space-between;
        align-items:center;
        gap:20px;
        .image{
            display:flex;
            flex-direction:column;
            height:320px;
            width:300px;
            overflow:hidden;
            align-items:center;
                text-align:center;
            img{
                height:250px;
                width:300px;display:block;
    object-fit:cover;

            }
            .text{
                height:70px;
            background-color:#f5f5f5;
                padding:4px;
                width:300px;
                gap: 0 !important;
                
            }
        }
    }
}
@media (max-width: 700px) {
   padding:100px 15px; 
   .top{
    justify-content:center !important;
        align-items:center;
   }
     .history, .general{
        padding-top:20px;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        justify-content:center !important;
        align-items:center;
        gap:20px;
        .image{

            height:300px;
            width:300px !important;
       
            img{
                height:300px;
                width:300px;
            }
        }
}
}
`