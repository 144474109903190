import { Wrapper } from "../custom/wrapper";
import styled from "styled-components";
import { Icon } from '@iconify/react';
import { Header } from "../custom/header";
import { Button } from "../custom/button";
export default function Welcome() {
  return (
    <Wrapper deep>
      <Intro>
        {" "}
        <Header className="header">
          <h5><Icon className="header-icon" icon="svg-spinners:bouncing-ball" color="pink" />Welcome</h5>
          <h3>We are pleased to have you here</h3>
        </Header>
        <div className="body">
          <div className="image">
            <img src="./images/proprietress.jpeg" />
          </div>
          <div className="about">
            <p className="words">
              It gives me great pleasure to welcome all parents and prospective
              pupils to Eminent Zion School, Akole Oke-ata, Abeokuta; an
              institution where children are trained to excel. Here, all
              resources (human and material) are put at our pupils' disposal in
              harnessing their God-given potential and talents, aimed at making
              them the best they can be. Over the years, we have remained
              steadfastly committed to the pursuit of our vision: to produce and
              nurture pupils, irrespective of their creed and religious
              background to become responsible and trustworthy adults with the
              required leadership qualities for pronounced benefits nationally
              and internationally. While assuring all our intending parents and
              pupils of an enduring and fruitful stay in the institution, Eminent
              Zion School remains the best choice ever!
            </p>
            <div className="infos">
              <div className="info">
                <h3>20+</h3>
                <p>Years Experience</p>
              </div>
              <div className="info">
                <h3>2000+</h3>
                <p>Students Trained</p>
              </div>
              <div className="info">
                <h3>#1</h3>
                <p>Top 1 Teachers</p>
              </div>
            </div>
            <div className="contact">

              <h5>
                Mrs Oluwadairo Ruth <br/> <span>Co-ordinator</span>
              </h5>
            </div>
          </div>
        </div>
      </Intro>
    </Wrapper>
  );
}
const Intro = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px 100px;
  .header {
    text-align: center;
  }
  .body {
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding-top: 20px;
    align-items: center;
    .image {
      width: 50%;
      background: linear-gradient(to right, #0082e6 70%, transparent 30%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 450px;

      img {
        height: 90% !important;
        width: 90%;
        display: block;
        object-fit: cover;
        margin: auto;
      }
    }
    .about {
      padding-top: 30px;
      width: 50%;
      display: flex;
      flex-direction: column;
      p {
        font-size: 17px;
      }
      .infos{
        display: flex;
        gap: 20px;
        .info{
          display:flex;
flex-direction: column;
align-items: left;
          h3{
            font-weight:1000;
            color:#0082ec;
            font-size: 35px;
          }
        }
      }
      .contact {
        display: flex;
        /* flex-direction: row; */
        justify-content: left;
        gap: 20px;
        align-items:center;
        gap: 50px;
        h5{
          font-weight:600;
        }
        span{
          font-weight:300 !important;

        }
        a{
          display: flex;
          align-items: baseline;
          gap:5px;
        }
        @media (max-width: 430px) {
          h3 {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 858px) {
    padding: 25px 15px;
    .body {
      flex-direction: column;
      .image {
        width: 100%;
        height: 300px;
        border: 0;
        background: linear-gradient(to top, #0082e6 70%, transparent 30%);
      }
      .about {
        padding-top: 0;
        width: 100%;
        .words{
          line-height:1.5;
        }
        .infos{
        .info{
          align-items: center !important;
          justify-content: center !important;
          h3{
            font-weight:1000;
            color:#0082ec;
            font-size: 35px;
          }
        }
      }
        .contact{
          padding-top: 30px;
          justify-content: center;
          text-align:center;
        }
        p {
          text-align: center;
        }
      }
    }
  }
`;
